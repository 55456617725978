import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {
  AUTO_GROUP_COL_DEF,
  COLUMN_HEADER_ID,
  DEFAULT_COL_DEF,
  DEFAULT_TABLE_ROW_HEIGHT,
  ROW_ID,
  SEEQ_AG_GRID_ID,
  SEEQ_ROW_INDEX,
  SIMPLE_TABLE_STRIPED_FIELD,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TableBuilderColumnType,
  TableBuilderHeaderType,
} from '@/tableBuilder/tableBuilder.constants';
import { AgGridApi, TableBuilderSimpleAgGridProps } from '@/tableBuilder/tableBuilder.types';
import { getStripedColor } from '@/utilities/tableBuilderHelper.utilities';
import {
  addScreenshotSetup,
  autoSizeColumns,
  createSimpleColDefs,
  firstNonEmptyValueAggFunc,
  getDomLayout,
  getFullGroupedNodePath,
  getRowId,
  getSimpleTableHeaderComponentParams,
  lastNonEmptyValueAggFunc,
  onColumnMoved,
  onRowDragEnd,
  rangeAggFunc,
  resizeAgGridHelper,
  standardDeviationAggFunc,
} from '@/tableBuilder/tableBuilderAgGrid.utilities';
import classNames from 'classnames';
import { GridReadyEvent, IRowNode } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { isPresentationWorkbookMode } from '@/utilities/utilities';
import { useDebounce } from '@/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { useTranslation } from 'react-i18next';

export const TableBuilderSimpleAgGrid: React.FunctionComponent<TableBuilderSimpleAgGridProps> = (
  props: TableBuilderSimpleAgGridProps,
) => {
  const {
    simpleColumns,
    simpleTableData,
    isTransposed,
    isStriped,
    darkMode,
    setAgGridElement,
    onColumnResized,
    updateContentMeasurements,
    autoGroupColumn,
    rowGroupPaths,
    handleRowGroupOpened,
    handleRowGroupChanged,
    onAgGridReady,
    contentResizeState,
  } = props;

  const { t } = useTranslation();
  const wrapperRef = useRef<any>(null);
  const rowNodeOutsideGridRef = useRef<IRowNode>();
  const [agGridApi, setAgGridApi] = useState<AgGridApi>();
  const setAgGridElementWrapper = (el: AgGridReact) => {
    if (setAgGridElement) {
      setAgGridElement(el);
    }
    if (wrapperRef.current) {
      wrapperRef.current.__AG_GRID__ = el;
    }
  };
  const gridReady = (params: GridReadyEvent) => {
    setAgGridApi({ api: params.api, columnApi: params.columnApi });
    addScreenshotSetup();
  };

  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const columnDefs = createSimpleColDefs(props);

  const autoSizeColumnsDebounce = useDebounce(autoSizeColumns, DEBOUNCE.MEDIUM_WITH_HEADLESS_RENDER_SUPPORT);

  const rows = isTransposed
    ? simpleColumns.map((column, columnIndex) => {
        const agGridRow: Record<string, any> = {};
        agGridRow[ROW_ID] = column.key;
        _.forEach(simpleTableData, (row, rowIndex) => {
          const cell = row.cells[columnIndex];
          if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
            agGridRow[rowIndex.toString()] = column?.cells?.[row.itemId];
          } else {
            agGridRow[rowIndex.toString()] = cell;
          }
        });

        agGridRow[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN] = {
          headerComponentParams: getSimpleTableHeaderComponentParams(props, column, columnIndex, false),
        };

        agGridRow[COLUMN_HEADER_ID] = column.key;

        return agGridRow;
      })
    : _.map(simpleTableData, (row, rowIndex) => {
        const agGridRow: Record<string, any> = {};
        agGridRow[SIMPLE_TABLE_STRIPED_FIELD] = getStripedColor(isStriped, rowIndex, darkMode);
        const hasSourceItem = row.itemId !== row.formulaItemId;
        agGridRow[ROW_ID] = row.itemId + (hasSourceItem ? row.formulaItemId : rowIndex.toString());
        agGridRow[SEEQ_ROW_INDEX] = rowIndex;
        _.forEach(columnDefs, (def, columnIndex) => {
          const column = simpleColumns[columnIndex];
          const cell = row.cells[columnIndex];

          if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
            agGridRow[def.field!] = column?.cells?.[row.itemId];
          } else {
            agGridRow[def.field!] = cell;
          }
        });

        return agGridRow;
      });

  const [lastTransposed, setLastTransposed] = useState(!isTransposed);
  const showTable = isTransposed === lastTransposed;

  const autoSizeHelper = () =>
    resizeAgGridHelper(
      autoSizeColumnsDebounce,
      columnDefs,
      autoGroupColumn,
      isInteractiveContent,
      agGridApi,
      wrapperRef.current,
      showTable,
      setLastTransposed,
      isTransposed,
      updateContentMeasurements,
      onAgGridReady,
    );

  useEffect(() => {
    const shouldAutoSize = agGridApi?.columnApi && simpleColumns.length > 0 && simpleTableData.length > 0;
    if (shouldAutoSize) {
      autoSizeHelper();
    }
  }, [simpleTableData, simpleColumns, agGridApi, isTransposed, updateContentMeasurements, contentResizeState]);

  return (
    <div
      ref={wrapperRef}
      id={SEEQ_AG_GRID_ID}
      data-testid={'simpleTable'}
      className={classNames('flexFillOverflow overflowAuto', {
        hidden: !showTable && !isInteractiveContent && !headlessRenderMode(),
      })}>
      <AgGridReact
        ref={setAgGridElementWrapper}
        domLayout={getDomLayout(isInteractiveContent)}
        rowData={rows}
        modules={[ClipboardModule, RowGroupingModule]}
        rowSelection="multiple"
        columnDefs={columnDefs}
        className={classNames({ 'transposed-simple-table': isTransposed })}
        rowDragManaged={true}
        animateRows={true}
        suppressColumnVirtualisation={true}
        suppressRowVirtualisation={isPresentationWorkbookMode() && headlessRenderMode()}
        suppressMaxRenderedRowRestriction={true}
        suppressFieldDotNotation={true}
        rowDragText={() => ''}
        suppressDragLeaveHidesColumns={true}
        onColumnMoved={(event) => onColumnMoved(event, props.moveColumn)}
        onRowDragEnd={(event) => onRowDragEnd(event.node, simpleColumns, props.moveColumn, rowNodeOutsideGridRef)}
        onRowDragLeave={(event) => {
          rowNodeOutsideGridRef.current = event.node;
        }}
        onDragStopped={() => {
          if (rowNodeOutsideGridRef.current) {
            onRowDragEnd(rowNodeOutsideGridRef.current, simpleColumns, props.moveColumn, rowNodeOutsideGridRef);
          }
        }}
        defaultColDef={DEFAULT_COL_DEF}
        autoGroupColumnDef={{ ...AUTO_GROUP_COL_DEF, headerName: t('TABLE_BUILDER.ENABLE_GROUPING_BAR') }}
        functionsReadOnly={isInteractiveContent} // Re-enable as part of CRAB-40362
        onColumnResized={isInteractiveContent ? undefined : onColumnResized}
        onGridReady={gridReady}
        overlayNoRowsTemplate="<div></div>"
        getRowId={getRowId}
        rowGroupPanelShow={isTransposed || !autoGroupColumn ? 'never' : 'always'}
        rowHeight={DEFAULT_TABLE_ROW_HEIGHT}
        onColumnRowGroupChanged={(event) => {
          handleRowGroupChanged(event);
          autoSizeHelper();
        }}
        onRowGroupOpened={(event) => {
          handleRowGroupOpened(event);
          autoSizeHelper();
        }}
        isGroupOpenByDefault={(params) => {
          if (!params?.rowNode.key) {
            return false;
          }
          return rowGroupPaths.includes(getFullGroupedNodePath(params.rowNode));
        }}
        headerHeight={props.headers.type === TableBuilderHeaderType.None ? 0 : undefined}
        suppressAggFuncInHeader={true}
        aggFuncs={{
          stdDev: standardDeviationAggFunc,
          range: rangeAggFunc,
          first: firstNonEmptyValueAggFunc,
          last: lastNonEmptyValueAggFunc,
        }}
      />
    </div>
  );
};
